<template>
  <div class="iss-main-grid">
    <a-modal
      title="重新分发"
      :visible="visible"
      :footer="null"
      centered
      width="880px"
      @cancel="handleCancel"
    >
      <div>
        <search-form :items="itemsModal" @fnSearch="handleFnSearchModal" />
        <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
          <a-row>
            <a-col :span="8">
              <a-form-item
                label="过期时间"
                v-bind="validateInfos.expirationHour"
                required
              >
                <a-input-number
                  v-model:value="form.expirationHour"
                  :min="1"
                  @blur="handleBlurDtae"
                  :max="99"
                />
                <span class="tiem">小时</span>
              </a-form-item>
            </a-col>
            <!--            <a-col :span="8">-->
            <!--              <a-form-item label="提醒频率" v-bind="validateInfos.remind" required>-->
            <!--                <a-input-number v-model:value="form.remind" :min="0" @blur="handleBlurRemind"/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :span="8">
              <a-form-item
                label="回收方式"
                v-bind="validateInfos.recoveryMode"
                required
              >
                <a-select
                  class="selectInput"
                  v-model:value="form.recoveryMode"
                  @change="handleFnSearch"
                >
                  <a-select-option
                    v-for="item in reclaimList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <grid
          ref="gridRefModal"
          :allow-selection="{ type: 'radio' }"
          :columns="columnsModal"
          :code="$route.path"
          :url="urlModal"
          :search="searchModal"
          :url-params="{ marketingType: 2 }"
          :btn-operation="null"
          :scroll="{ x: 800, y: gridHeightModal }"
        >
        </grid>
        <a-row type="flex" justify="space-between" align="middle" class="mt-24">
          <a-col
            >您已经选择了{{
              activeItem.length
            }}条商机，请分发给指定营销人员</a-col
          >
          <a-col>
            <a-space>
              <a-button @click="handleCancel">取消</a-button>
              <a-button type="primary" @click="handleFnBatch">分发</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
// createVNode,
import { reactive, ref, toRefs, watch } from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
// import Operation from '@/components/operation';
import distributionApi from '@/api/distribution';
// import dictionaryApi from '@/api/dictionary.js';
import {
  message,
  Row,
  Col,
  Button,
  Space,
  Select,
  Form,
  InputNumber,
  Modal,
} from 'ant-design-vue';
// import * as icons from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputNumber: InputNumber,
    Grid,
    // Operation,
    // AModal: Modal,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ASpace: Space,
    // ATag: Tag,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const gridRef = ref();
    const gridRefModal = ref();
    const state = reactive({
      search: {},
      searchModal: {},
      TagList: [],
      visible: false,
      length: 0,
      gridSelectedRowKeys: [],
      reclaimList: [
        { label: '手动回收', value: '0' },
        { label: '自动回收', value: '1' },
      ],
      activeItem: {
        length: '',
        gridIds: [],
        distributeType: '',
      },
    });
    const form = reactive({
      recoveryMode: '1',
      expirationHour: '48',
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      recoveryMode: [required],
      expirationHour: [required],
      // remind: [required],
    });

    watch(
      () => props.visible,
      value => {
        if (value) {
          Object.assign(state.activeItem, props.initValue);
        }
      }
    );
    return {
      gridRef,
      gridRefModal,
      validateInfos,
      ...toRefs(state),
      form,
      itemsModal: [
        { key: 'userName', label: '姓名' },
        { key: 'mobileNum', label: '手机' },
      ],
      columnsModal: [
        { dataIndex: 'userName', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'companyName', title: '公司', width: 140, ellipsis: true },
      ],
      btnOperation: [],
      url: distributionApi.pageUrl,
      urlModal: distributionApi.marketingUserPageUrl,
      gridHeight: document.body.clientHeight - 457,
      gridHeightModal: document.body.clientHeight - 416,
      handleBlurDtae: () => {
        let num = form.expirationHour;
        if (num % 1 !== 0) {
          form.expirationHour = '';
          return message.error('请输入整正数');
        }
      },
      // handleBlurRemind: () => {
      //   let num = form.remind;
      //   if (num % 1 !== 0) {
      //     form.remind = '';
      //     return message.error('请输入整正数');
      //   }
      // },
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        state.search = temp;
      },
      handleFnSearchModal: value => {
        const temp = Object.assign({}, value);
        state.searchModal = temp;
      },
      handleCancel: () => {
        resetFields();
        context.emit('update:visible', false);
      },

      handleFnBatch: () => {
        validate().then(() => {
          const length = gridRefModal.value.selectedRows.length;
          const getTime = `${new Date().getTime()}`;
          if (length) {
            //  entry: 1,
            distributionApi
              .redistribution('', {
                ids: state.activeItem.gridIds,
                promoteUserId: gridRefModal.value.selectedRows[0].id,
                ...form,
                beginTime: getTime,
                distributeType: state.activeItem.distributeType,
              })
              .then(res => {
                if (res) {
                  state.visible = false;
                  // message.success('操作成功');
                  Modal.info({
                    title: `本次分发商机${res.totalNum}条，其中有效分发${res.successNum}条`,
                    centered: true,
                  });
                  gridRefModal.value.refreshGrid();
                  context.emit('fnOk', false);
                }
              });
          } else {
            message.info('请先选择推广人员');
          }
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main-grid {
//  max-height: calc(100vh - 161px);
//  overflow-y: auto;
//}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
}
.tiem {
  margin-left: 5px;
}
</style>
