<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-20 mb-20">
      <a-tabs v-model:activeKey="activeKey" @change="handleTab(activeKey)">
        <a-tab-pane key="" tab="全部" />
        <a-tab-pane key="1" tab="已回收" />
        <a-tab-pane key="0" tab="未回收" />
      </a-tabs>
      <grid
        ref="gridRef"
        :allow-selection="true"
        :columns="columns"
        :code="$route.path"
        :url="tabUrl"
        :url-params="{ distributeType: 3 }"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <template #scoreLevel="{ text }">
          <span class="score-level">{{ scoreLevel[text] }}</span>
        </template>
        <template #recoveryMode="{ text }">
          <span v-if="text === 0">手动回收</span>
          <span v-if="text === 1">自动回收</span>
        </template>
        <template #distributionMethod="{ text }">
          <span v-if="text === 0">手动分发</span>
          <span v-if="text === 1">自动分发</span>
        </template>
        <template #recoveryStatus="{ text }">
          <span v-if="text === 0">未回收</span>
          <span v-if="text === 1">已回收</span>
        </template>
      </grid>
    </div>
  </div>
  <pt-modal
    v-model:visible="visible"
    :init-value="activeItem"
    @fnOk="handleFnOk"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import distributionApi from '@/api/distribution';
import dictionaryApi from '@/api/dictionary.js';
import ptModal from '@/views/opportunity/components/distribution/ptModal';
import { message, Tabs } from 'ant-design-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ptModal,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    // ATag: Tag,
  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      tabUrl: distributionApi.expireDistributePage,
      activeKey: '',
      visible: false,
      gridSelectedRowKeys: [],
      search: {
        recoveryStatus: '',
      },
      TagList: [],
      activeItem: {
        length: '',
        gridIds: [],
        distributeType: 3,
      },
    });
    return {
      gridRef,
      ...toRefs(state),
      handleTab: activeKey => {
        if (activeKey === '1') {
          state.search.recoveryStatus = '1';
        } else if (activeKey === '0') {
          state.search.recoveryStatus = '0';
        } else {
          state.search.recoveryStatus = '';
        }
      },
      scoreLevel: { high: '高分', middle: '中分', low: '低分' },
      items: [
        { key: 'contactName', label: '商机姓名' },
        { key: 'mobileNum', label: '手机' },
        {
          key: 'city',
          label: '城市',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          showSearch: true,
          url: dictionaryApi.cityUrl,
        },
        { key: 'company', label: '公司' },
        // {
        //   key: 'industryLv1Code',
        //   label: '行业',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   showSearch: true,
        //   url: dictionaryApi.industryLv1CodeUrl,
        // },
        { key: 'industryLv1Code', label: '行业' },
        {
          key: 'leadPoolId',
          label: '线索池',
          type: 'select',
          valueKey: 'leadPoolId',
          labelKey: 'leadPoolName',
          showSearch: true,
          filterOption: 'label',
          url: distributionApi.clueUrl,
        },
        {
          key: 'scoreLevel',
          label: '所属分阶',
          type: 'select',
          dataset: [
            { label: '全部', value: '' },
            { label: '高', value: 'high' },
            { label: '中', value: 'middle' },
          ],
        },
        {
          key: 'recoveryStatus',
          label: '回收状态',
          type: 'select',
          dataset: [
            { label: '已回收', value: '1' },
            { label: '未回收', value: '0' },
          ],
        },
        {
          key: 'distributionMethod',
          label: '分发方式',
          type: 'select',
          dataset: [
            { label: '手动分发', value: '0' },
            { label: '自动分发', value: '1' },
          ],
        },
        {
          key: 'recoveryMode',
          label: '回收方式',
          type: 'select',
          dataset: [
            { label: '手动回收', value: '0' },
            { label: '自动回收', value: '1' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'contactName',
          title: '商机姓名',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'city', title: '城市', width: 120, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        {
          dataIndex: 'industryLv1Code',
          title: '行业',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'leadPoolName',
          title: '线索池',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: 'scoreLevel',
          title: '所属分阶',
          slots: { customRender: 'scoreLevel' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'recoveryStatus',
          title: '回收状态',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'recoveryStatus' },
        },
        {
          dataIndex: 'distributionMethod',
          title: '分发方式',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'distributionMethod' },
        },
        {
          dataIndex: 'recoveryMode',
          title: '回收方式',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'recoveryMode' },
        },
        {
          key: 'id',
          fixed: 'right',
          title: '操作',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'reclaim',
          label: '批量回收',
          btnType: 'primary',
          permission: 'bind:reclaim',
          fnClick: selectedRowKeys => {
            const length = selectedRowKeys.length;
            if (state.search.recoveryStatus === '1')
              return message.error('已回收商机不支持再次回收');
            if (length) {
              distributionApi
                .recoveryBusiness('', {
                  ids: selectedRowKeys,
                  type: 0,
                })
                .then(res => {
                  if (res) {
                    message.success('操作成功');
                    gridRef.value.refreshGrid();
                  } else {
                    message.error('已回收商机不支持再次回收');
                  }
                });
            } else {
              message.info('请先选择数据');
            }
          },
        },
        {
          type: 'reclaimAll',
          label: '一键回收所有商机',
          btnType: 'primary',
          permission: 'bind:reclaimAll',
          fnClick: () => {
            // state.search.recoveryStatus = '1'; 需要按钮置灰或者提示
            console.log('fnClick', state.search.recoveryStatus === '1');
            if (state.search.recoveryStatus === '1') {
              return message.error('已回收商机不支持再次回收');
            } else {
              distributionApi
                .recoveryBusiness('', {
                  type: 1,
                })
                .then(() => {
                  message.success('操作成功');
                  gridRef.value.refreshGrid();
                });
            }
          },
        },
        {
          type: 'redistribution',
          label: '重新分发',
          btnType: 'primary',
          permission: 'distribution:redistribution',
          fnClick: selectedRowKeys => {
            const length = selectedRowKeys.length;
            if (length) {
              state.length = length;
              state.gridSelectedRowKeys = selectedRowKeys;
              state.activeItem.length = selectedRowKeys.length;
              state.activeItem.gridIds = selectedRowKeys;
              state.visible = true;
            } else {
              message.info('请先选择数据');
            }
          },
        },
        {
          type: 'unDistribute',
          label: '批量不分发',
          permission: 'bind:unDistribute',
          fnClick: selectedRowKeys => {
            const length = selectedRowKeys.length;
            if (length) {
              distributionApi
                .expireBatchNoDistribute('', selectedRowKeys)
                .then(() => {
                  message.success('操作成功');
                  gridRef.value.refreshGrid();
                });
            } else {
              message.info('请先选择数据');
            }
          },
        },
      ],
      options: [
        // {
        //   type: 'distribute',
        //   label: '分发',
        //   icon: 'RedoOutlined',
        //   permission: 'distribution:distribute',
        //   fnClick: () => {
        //
        //   },
        // },
        // reclaim
        {
          type: 'reclaim',
          label: '回收',
          icon: 'ReloadOutlined',
          permission: 'distribution:reclaim',
          show: record => {
            if (record.recoveryStatus === 0) {
              return true;
            } else {
              return false;
            }
          },
          fnClick: record => {
            distributionApi
              .recoveryBusiness('', {
                ids: [record.id],
                type: 0,
              })
              .then(() => {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });
          },
        },
      ],
      url: state.tabUrl,
      gridHeight: document.body.clientHeight - 419,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        state.activeKey = '';
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOk: value => {
        state.visible = value;
        gridRef.value.refreshGrid();
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 155px);
  overflow-y: auto;
}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
}
.iss-grid-wrap /deep/ .ant-tabs-bar {
  border-bottom: none;
  margin: 0px 0px 15px 0px;
}
</style>
